import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

type BreakpointsType = {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
}

interface IImgWebpProps {
  breakPointsSize: BreakpointsType;
  breakPointsHeight?: BreakpointsType;
}

export const ImgWebp = styled.img<IImgWebpProps>`
  width: ${(props: IImgWebpProps) => props.breakPointsSize.sm};
  height: ${(props: IImgWebpProps) => props.breakPointsHeight?.sm};

  @media ${device.tablet} {
    width: ${(props: IImgWebpProps) => props.breakPointsSize.md};
    height: ${(props: IImgWebpProps) => props.breakPointsHeight?.md};
  }

  @media ${device.desktopLG} {
    width: ${(props: IImgWebpProps) => props.breakPointsSize.lg};
    height: ${(props: IImgWebpProps) => props.breakPointsHeight?.lg};
  }

  @media ${device.desktopXL} {
    width: ${(props: IImgWebpProps) => props.breakPointsSize.xl};
    height: ${(props: IImgWebpProps) => props.breakPointsHeight?.xl};
  }

  @media ${device.desktopXXL} {
    width: ${(props: IImgWebpProps) => props.breakPointsSize.xxl};
    height: ${(props: IImgWebpProps) => props.breakPointsHeight?.xxl};
  }
`
