import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled, { keyframes } from 'styled-components'

export const CloseButton = styled.button`
  @media (max-width: ${breakpoints.sm}) {
    right: 10px;
  }

  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;

  &:focus{
    outline: none;
  }
`

export const ErrorMessage = styled.p`
  text-align: end;
  color: red;
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
`

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`

export const Container = styled.section`
  background-color: white;
  overflow-y: auto; 
  min-height: 100vh;
  padding: 30px 24px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.5s ease-in-out;
  animation: ${animatedModal} 0.5s ease-in-out forwards;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    max-width: 548px;
    padding: 40px 51.5px;
  }

  label{
    color: ${grayscale[400]};
    margin-bottom: 8px;
    font-family: "Inter", Helvetica, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.52px;
  }
  
  .form--default {
    height: 100%;
    .form-body{
    padding: 24px 0px;
      
      @media (min-width: ${breakpoints.md}) {
        padding: 24px 52px;
      }
      .radioDiv{
        .radio{
          margin-right: 16px;
          display: flex;
          align-items: center;
          flex-direction: row;

          label{
            font-size: 14px;
            margin: 0 0 0 4px;
          }
        }
      }
    }
    .input{
      padding: 14px 0 0 0;
    } 
  }
`

export const FormTitle = styled.h5`
  font-family: "Inter", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 24px;
  margin:0 ;
`

export const SuccessScreenSec = styled.section`
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow-y: auto; 
  min-height: 100vh;
  padding: 30px 24px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.5s ease-in-out;
  animation: ${animatedModal} 0.5s ease-in-out forwards;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    max-width: 548px;
    padding: 40px 51.5px;
  }

  h5{
    margin: 24px 0 0 0;
    line-height: 24px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    font-family: Inter;
  }
`

export const ErrorScreenSec = styled.section`
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow-y: auto; 
  min-height: 100vh;
  padding: 30px 24px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.5s ease-in-out;
  animation: ${animatedModal} 0.5s ease-in-out forwards;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    max-width: 548px;
    padding: 40px 51.5px;
  }

  h5{
    margin-top: 24px;
    line-height: 24px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    font-family: Inter;
  }

  .tryAgainButton{
    margin: 24px 0;
  }
`

export const LinkButton = styled.a`
  text-decoration: none;
  color: ${orange.extra};
  width: 100%;
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  line-height: 16.94px;
  padding: 15px;
  margin: 9px auto;
  display: flex;
  justify-content: center;


  &:focus{
    outline: none;
    border: none !important;
  }
`
